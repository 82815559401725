import { Component, OnInit, ElementRef, AfterViewInit} from '@angular/core';
declare var $: any;

interface JQuery {
    chosen(options?:any):JQuery;
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})


export class HomeComponent implements OnInit,AfterViewInit {

constructor() { }
	ngAfterViewInit() {
		$.scrollify({
	        section: ".slider",
	        scrollbars: false,
	        before: function(i, panels) {
	          $('.sliders > section').eq(i).siblings().removeClass('active');
	          $('.sliders > section').eq(i).addClass("active");
	        },
	        after: function(i, panels) {
	        },
	        afterResize: initialPosition,
	        afterRender: initialPosition
	      });


		function initialPosition() {
	      var current = $.scrollify.current();
	      $(current).addClass("active");
	    }

	    $(document).on("click", ".side-menu a", function(e) {
	      if($(this).attr("href") !== '#/findhopper'){
	        $.scrollify.move($(this).attr("href"));
	        e.preventDefault();
	      }
	    });
	}

	ngOnInit() {
	  	setTimeout(function() {
	        //$.scrollify.instantMove("#home");
			$('body').toggleClass('loading');
	    }, 2000)
	}

}
