import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mumbai-ad-landing',
  templateUrl: './mumbai-ad-landing.component.html',
  styleUrls: ['./mumbai-ad-landing.component.scss']
})
export class MumbaiAdLandingComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
